import React from 'react';

/**
 * Paste in your SVG logo and return it from this component.
 * Make sure you have a height set for your logo.
 * It is recommended to keep the height within 25-35px.
 * Logo comes with a property value called `fill`. `fill` is useful
 * when you want to change your logo depending on the theme you are on.
 */
export default function Logo({ fill }) {
  return (
    <svg width="225" height="75">
  <g fill={fill} transform="scale(0.75)">
	<path d="M39.5004883,65.7988281c1.800293,0.8642578,3.168457,1.9433594,4.1762695,3.1679688
		c0.9360352,1.2236328,1.65625,2.4482422,2.0883789,3.7441406c0.3598633,1.2246094,0.5756836,2.2324219,0.6479492,3.0966797
		l0.0717773,1.2958984h-6.8104897c-1.6418495,0-3.2119675-0.7655563-4.1560135-2.1088562
		c-0.7453728-1.0606003-1.6565819-1.7978363-2.7146492-2.2114563c-1.1518555-0.5039062-2.3759766-0.7197266-3.6723633-0.7197266
		c-0.5756836,0-1.4399414,0.0712891-2.7358398,0.2871094c-1.2963867,0.1445312-2.8085938,0.6484375-4.6083984,1.3681641
		c-1.800293,0.7919922-3.2402344,1.5126953-4.4643555,2.1601562l-1.7285156,1.0087891l-2.4691744-2.393692
		c-1.3200426-1.2796936-1.6718769-3.2610779-0.8731108-4.9170074l4.5664062-9.4666443
		c-0.5756836-0.2158203-1.0078125-0.4326172-1.4399414-0.5761719c-0.6479492-0.3603516-1.1523438-0.6484375-1.512207-0.8642578
		l-0.5039062-0.5039062c-2.7363281-2.9521484-4.1044922-6.3369141-4.1044922-10.2246094
		c0-3.9604492,1.512207-7.3447266,4.6083984-10.0087891c3.0244141-2.6645508,7.2006836-3.9604492,12.3852539-3.9604492h17.483902
		c1.3197594,0,2.3896332,1.0698738,2.3896332,2.3896332v3.2988434c-4.3925781,0-7.128418-0.0717773-8.2084961-0.144043
		c2.7358398,2.3041992,4.1040039,5.0405273,4.1040039,8.2089844c0,3.9599609-1.5117188,7.34375-4.5361328,10.1523438
		c-3.0244141,2.7363281-6.7685547,4.1767578-11.3051758,4.1767578c-0.3598633,0-0.7919922-0.0722656-1.2958984-0.0722656h-0.5043945
		l-3.527832,5.9765625l1.2241211-0.7919922c0.7919922-0.5039062,2.0161133-1.0800781,3.7441406-1.5839844
		c1.7280273-0.5761719,3.9604492-0.9365234,6.6962891-1.1523438C35.3242188,64.5029297,37.7001953,64.9345703,39.5004883,65.7988281
		z M18.402832,42.0371094c-1.512207,1.7280273-2.3041992,3.671875-2.3041992,5.9042969
		c0,5.2558594,3.2402344,7.9921875,9.6484375,8.2080078c6.1206055,0,9.2890625-2.8076172,9.4331055-8.4238281
		c0-5.4008789-3.168457-8.1367188-9.5048828-8.2089844C22.362793,39.5166016,19.9868164,40.3808594,18.402832,42.0371094z"/>
	<path d="M51.737793,69.7587891V36.563488c0-1.4311485,1.1601715-2.59132,2.59132-2.59132h3.6728401v7.2006836
		c1.5844727-4.1762695,5.7607422-7.7768555,11.4492188-7.7768555c3.0244141,0,5.4726562,0.7202148,7.4887314,2.1602325
		l-3.2401962,5.7606659c-1.9443359-1.512207-4.1044922-2.0161133-5.9765625-2.0161133
		c-4.7524414,0-8.3530273,4.9682617-9.0009766,7.9926758c-0.5039062,2.5200195-0.5761719,5.5444336-0.5761719,7.8481445v11.1187973
		c0,1.932106-1.5662842,3.4983902-3.498394,3.4983902H51.737793z"/>
	<path d="M117.4038086,37.5808525v31.5294991h-3.6316681c-1.6129913,0-2.920578-1.3075867-2.920578-2.920578v-1.110672
		c-3.456543,3.5996094-7.6328125,5.3994141-12.5292969,5.3994141s-9.0727539-1.7998047-12.6010742-5.3994141
		c-3.4560547-3.6728516-5.1845703-8.0654297-5.1845703-13.1777344s1.7285156-9.5043945,5.1845703-13.1049805
		c3.5283203-3.6000977,7.7045898-5.4003906,12.6010742-5.4003906s9.0727539,1.7285156,12.5292969,5.3286133v-2.5922852
		l3.7950211-0.7924309C116.0684738,35.0429878,117.4038086,36.1282959,117.4038086,37.5808525z M107.1069336,60.9746094
		c2.3041992-2.5205078,3.456543-5.5449219,3.456543-9.0732422c0-3.527832-1.1523438-6.4799805-3.456543-9.0004883
		c-2.3041992-2.5200195-5.1123047-3.7441406-8.3525391-3.7441406s-6.0483398,1.2241211-8.3525391,3.7441406
		c-2.3041992,2.5205078-3.456543,5.4726562-3.456543,9.0004883c0,3.5283203,1.1523438,6.5527344,3.456543,9.0732422
		c2.3041992,2.4482422,5.1123047,3.671875,8.3525391,3.671875S104.8027344,63.4228516,107.1069336,60.9746094z"/>
	<path d="M126.4731445,86.8242188V33.972168h2.8758698c1.791748,0,3.2442474,1.4524994,3.2442474,3.2442474v2.876358
		c2.8085938-4.3925781,8.2089844-6.6967773,12.8173828-6.6967773c9.4321289,0,15.3374023,5.9047852,15.3374023,17.3535156
		c0,14.3295898-9.8647461,19.7290039-19.4418945,19.7290039c-3.2402344,0-5.7602539-0.5039062-8.4248047-1.1513672v12.2120438
		c0,2.9188385-2.3661804,5.2850266-5.2850266,5.2850266H126.4731445z M141.0180664,64.6464844
		c6.9848633,0,13.2495117-3.671875,13.2495117-13.3212891c0-8.5683594-4.3208008-12.0244141-10.7290039-12.0244141
		c-3.5283203,0-6.9848633,1.8720703-8.9287109,4.5361328c-1.0800781,1.4404297-1.7285156,3.8886719-1.7285156,5.9765625v13.3212891
		C134.8256836,63.9267578,137.0581055,64.6464844,141.0180664,64.6464844z"/>
	<path d="M195.0195312,69.7587891V49.4536133c0-2.5205078-0.2880859-5.3286133-1.296875-6.9848633
		c-1.3681641-2.4482422-3.9599609-3.3842773-7.1279297-3.3842773c-3.3125,0-7.4169922,2.3041992-9.6494141,5.6166992
		c-0.9355469,1.4399414-1.5839844,3.5283203-1.5839844,6.1206055v18.9370117h-2.7151642
		c-2.0396118,0-3.6930389-1.6534271-3.6930389-3.6930389V16.690918h3.2779694c1.6888733,0,3.0579681,1.3691025,3.0579681,3.0579758
		v20.7760086c2.2324219-3.9604492,7.3447266-7.2006836,13.1777344-7.2006836c5.6162109,0,9.3603516,1.9438477,11.3769531,5.7602539
		c1.2236328,2.2324219,1.5117188,5.9765625,1.5117188,8.6411133v19.3747711c0,1.4682083-1.1902161,2.658432-2.658432,2.658432
		H195.0195312z"/>
	<path d="M212.2236328,20.8671875c0.7919922-0.7919922,1.7275391-1.2241211,2.8076172-1.2241211
		c1.1523438,0,2.0166016,0.4321289,2.8085938,1.2241211c0.7197266,0.8637695,1.0800781,1.7998047,1.0800781,2.9521484
		c0,1.1518555-0.3603516,2.0883789-1.1523438,2.8803711c-0.7197266,0.7919922-1.65625,1.2241211-2.7363281,1.2241211
		s-2.015625-0.4321289-2.8076172-1.2241211c-0.7207031-0.7919922-1.0800781-1.7285156-1.0800781-2.8803711
		C211.1435547,22.6669922,211.5029297,21.6591797,212.2236328,20.8671875z M218.2001953,30.659668v39.0991211h-2.6847839
		c-1.9771118,0-3.5798645-1.6027603-3.5798645-3.5798645V35.9134789c0-1.6109734,0.9707336-3.0632095,2.4592896-3.6791687
		L218.2001953,30.659668z"/>
	<path d="M243.8320312,70.5507812c-11.7373047,0-17.7138672-7.6328125-17.7138672-18.6494141
		c0-11.5205078,7.5605469-18.793457,18.6494141-18.793457c3.071167,0,5.5193176,0.3505898,7.8021393,1.0801163
		c1.4494171,0.463192,2.4224701,1.8269691,2.4224701,3.3485947v3.9243164
		c-2.9521484-1.4404297-5.5439453-2.4482422-9.7919922-2.4482422c-9.0732422,0-12.6015625,6.3364258-12.6015625,12.7451172
		c0,7.8486328,4.0322266,12.8886719,12.1689453,12.8886719c3.7441406,0,7.4169922-1.0800781,10.9453125-3.4560547v3.3508759
		c0,1.8544846-1.0900879,3.5529022-2.7954102,4.2816086C250.2443085,69.96521,247.3309479,70.5507812,243.8320312,70.5507812z"/>
	<path d="M261.7578125,61.0458984c4.1044922,2.3769531,7.9208984,3.6005859,12.5292969,3.6005859
		c5.1123047,0,7.4882812-1.65625,7.4882812-5.1123047c0-2.3759766-1.8720703-3.1679688-6.1201172-4.6806641l-4.4648438-1.5839844
		c-5.2558594-1.9443359-8.4248047-4.6801758-8.4248047-9.9365234c0-6.2646484,4.4648438-10.0810547,13.2490234-10.0810547
		c3.8164062,0,7.7050781,0.7924805,10.8730469,2.0161133v6.480957c-3.5283203-1.65625-6.6240234-2.5922852-10.7285156-2.5922852
		c-4.3925781,0-6.8408203,1.3681641-6.8408203,4.6083984c0,2.0161133,1.1523438,2.8803711,5.1123047,4.3203125l4.1044922,1.5839844
		c6.625,2.5200195,9.8652344,4.6079102,9.8652344,10.152832c0,7.2001953-5.6171875,10.65625-14.1855469,10.65625
		c-4.1757812,0-8.7128906-0.8632812-12.4570312-2.8076172V61.0458984z"/>
</g>
</svg>
  );
}